<template>

    <div>
        <el-dialog :visible.sync="dia" title="常用地址" width="450px" @close="close">
        <div class="fp">
  
          <div class="fpform">
  
            <div>
              <p>联系人</p>
              <p>
                <input type="text" v-model="form1.name" placeholder="请输入联系人" 
                :maxlength="8" style="text-indent: 10px;">
              </p>
            </div>
            <div>
              <p>手机号</p>
              <p>
                <input type="text" v-model="form1.phone" placeholder="请输入手机号" style="text-indent: 10px;" :maxlength="11">
              </p>
            </div>
            <div>
              <p>地址</p>
              <p>
                <el-cascader :placeholder="ssq" :options="options" v-model="selectedOptions" @change="handleChange">
                </el-cascader>
              </p>
            </div>
            <div>
              <p></p>
              <p>
                <textarea placeholder="请输入详细地址" v-model="form1.address"></textarea>
              </p>
            </div>
            <div class="butt">
              <p @click="add">确定</p>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  
  </template>
  
  <script>
    import {
      regionData,
      CodeToText,
      TextToCode
    } from "element-china-area-data";
    
    import {
      addressBillinglistAPI,
      addressdelAPI,
      addressAddAPI,
      addressEditAPI
    } from "@/api/user"
    export default {
  
      data() {
        return {
          sign: 1,
          list: [],
          dia: false,
          form1: {
            phone: "",
            name: "",
            address: "",
            province: "",
            city: '',
            area: "",
            id: 0
          },
          ssq: "省市区",
          selectedOptions: [],
          options: regionData,
          addflag: true
  
        }
      },
      created() {
        
      },
      methods: {
        handleChange() {
          // var loc = "";
          // for (let i = 0; i < this.selectedOptions.length; i++) {
          //   loc += CodeToText[this.selectedOptions[i]];
          // }
          console.log(this.selectedOptions)
          this.form1.province = CodeToText[this.selectedOptions[0]]
          this.form1.city = CodeToText[this.selectedOptions[1]]
          this.form1.area = CodeToText[this.selectedOptions[2]]
        },
        
        close() {
          this.form1 = {
            phone: "",
            name: "",
            address: "",
            province: "",
            city: '',
            area: ""
          }
          this.selectedOptions = []
        },
        add() {
          if (this.addflag) {
            addressAddAPI(this.form1).then(res => {
              this.$message("添加地址成功！")
                this.$emit("getlist")
              this.dia = false
  
            })
          } else {
            addressEditAPI(this.form1).then(res => {
              this.$message("修改地址成功！")
              this.$emit("getlist")
              this.dia = false
              this.addflag = true
              this.close()
            })
          }
  
        },
        edit(item) {
          this.dia = true
          this.form1 = item
          //  this.selectedOptions= ["140000", "140200", "140213"]
          var list = []
          list[2] = TextToCode[item.province][item.city][item.area].code
          list[1] = TextToCode[item.province][item.city].code
          list[0] = TextToCode[item.province].code
          console.log(list)
          this.selectedOptions = list
          this.addflag = false
        }
      }
    }
  
  </script>
  
  <style lang="scss" scoped>
  
  
      .el-dialog__body {
        padding: 0px 20px;
      }
  
      .fp {
  
        >p {
          line-height: 40px;
          font-size: 12px;
          color: #999;
  
          strong {
            font-size: 16px;
            margin-right: 15px;
          }
        }
  
        .fpform {
          >div {
            display: flex;
            align-items: center;
            line-height: 45px;
            color: #333333;
  
            p:nth-child(1) {
              width: 100px;
            }
  
            input {
              background: #f6f7f9;
              border-radius: 4px;
              width: 287px;
              height: 34px;
            }
  
            textarea {
              background: #f6f7f9;
              border-radius: 4px;
              width: 287px;
              height: 80px;
              padding: 10px;
            }
  
          }
        }
  
        .butt {
          p {
            width: 70px;
            line-height: 35px;
            opacity: 1;
            font-size: 14px;
            background: #ff7c00;
            border-radius: 2px;
            text-align: center;
            color: #ffffff;
            margin: 10px auto;
            cursor: pointer;
          }
        }
      }
    
  
  </style>
  